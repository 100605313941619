module.exports = {
  //PARAMETERS
  developmentFeatures: true, //false or not assigned to disable development features
  captchaTokenFront: "6LcjXu0aAAAAABpuC2mj1E5H7dsJ5hQ5QThJkubd",
  stripe_pk:
    "pk_test_51Kt7JvETDYpSqO5yMU3sdg1C1yl60DbFVaG7N6Aa86qAIL7QGlL1noJuJSLjUPuDsljyRh0RbSUkqklLIuec9UK400JaHTOTtb", //TEST MADRID
  open_DOORS: false,
  more_payment_methods: false,
  access_code_digits: 4,
  "color-primary": "#28c76f",
  "color-map-nodisponible": "#cda077", // cda077 keep this color if the company doesnt ask to change it to their primary color
  useAnalytics: false,
  use_MERCHANT: false, //USE mercantilRegister param
  use_FAX: false,
  use_LOCATION_PREP: false,
  use_StorageGroups: false,
  use_TEST_DATA: false, //AUTO FILL FORMS WITH TEST DATA

  //FEATURES
  ALLOW_CHANGE_STORAGE: false, //ALLOW CHANGE STORAGE FEATURE
  
  //CHECKOUT PARAMETERS
  USE_RETENTION: false, //Allowed 19% retention
  use_PRORRATION: true, //SHOW PRORRATION AT CHECKOUT
  use_CONTRACTS: true, //SHOW SIGNABLE CONTRACTS AT CHECKOUT
  use_SIGNABLE: false, //SHOW SIGNABLE CONTRACTS AT CHECKOUT
  use_ASSURE: true, //SHOW ASSURES AT CHECKOUT
  use_ASSURE_PRORRATION: false, //APPLY PRORRATION TO ASSURES AT CHECKOUT
  use_ASSURE_EXTENSION: false, //SHOW ASSURE EXTENSION
  use_DEPOSIT: false, //SHOW DEPOSIT AT CHECKOUT
  use_LIQUIDATION: false,
  
  //ADD CUSTOMER CHECKOUT PARAMETERS
  allow_CREATE_CUSTOMER_PURCHASE: true, //ALLOWS TO CREATE A SINGLE CUSTOMER WITHOUT PURCHASE
  allow_NORMAL_PURCHASE: true, //ALLOWS NORMAL PURCHASE
  allow_LACK_PURCHASE: true, //ALLOWS LACK PURCHASE
  allow_BUY_PRODUCTS_PURCHASE: true, //ALLOWS BUY PRODUCTS PURCHASE
  allow_BOOKING_PURCHASE: false, //ALLOWS BOOKING PURCHASE

  //PAYMENT PARAMETERS
  use_CARD_PAYMENT_FOR_WORKERS: true, //ENABLE CARD PAYMENTS
  use_SEPA_DEBIT_PAYMENT_FOR_WORKERS: true, //ENABLE SEPA_DEBIT PAYMENTS
  use_TRANSFER_PAYMENT_FOR_WORKERS: false, //ENABLE TRANSFER PAYMENTS
  use_CARD_PAYMENT_FOR_CUSTOMERS: true, //ENABLE CARD PAYMENTS
  use_SEPA_DEBIT_PAYMENT_FOR_CUSTOMERS: false, //ENABLE SEPA_DEBIT PAYMENTS
  use_TRANSFER_PAYMENT_FOR_CUSTOMERS: false, //ENABLE TRANSFER PAYMENTS
  use_PAY_AT_SHOP: false, //ENABLE TRANSFER PAYMENTS

  //PARAM NEW COMPANY
  withLanding: false,
  withIndoorOpening: true, //tiene puerta principal interna
  withStorageOpening: false, //storages doors

  companyName: " M² Trasteros urbanos",
  phone: "658 44 84 77",
  city: "Madrid",
  province: "Madrid",
  email: "alquiler@m2trasterosurbanos.es",
  address: "PASEO DE JESUITAS 14, 28011 MADRID",
  postal_code: "28011",
  storageNaming: "BOX",
  commercialName: "M2 Trasteros Urbanos",
  payments_companyName: "M2 SELF STORE, S.L",
  payments_NIF: "B88513023",
  mercantilRegister: "",
  accountant_email: "esolis@gefrumon.com",
  contract_email: "admin@m2trasterosurbanos.es",
  url: "alquiler.m2trasterosurbanos.es",
  branch: "M2",

  //INVOICE PARAMETERS
  firstSizeM3: true,
  use_IRPF: false,
  IVA: 21, //IN PERCENT 21%
  RETENTION: 19, //IN PERCENT 19%
  CENTERNAME:'ES',
  automaticPayment:false,

  //CUSTOMER JS
  showUnsubcribeProcess:false,
  //URL
  workersURL:"/workers/login",

  CUSTOM_IMAGE_URL: 'https://m2trasterosurbanos.es/wp-content/uploads/2022/02/cropped-2-3.png',
  perPageLimit:5
};
